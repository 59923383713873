@import 'src/lib/styles/mixin.scss';

.container {
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;

  @include xs() {
    width: 97%;
  }
}
