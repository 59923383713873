@import 'src/lib/styles/mixin.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  min-width: 18em;
  border-radius: 12px;

  &.sm {
    width: 40vw;

    @include max-w-xs() {
      width: 95vw;
    }

    @include min-w-sm() {
      width: 85vw;
    }

    @include min-w-md() {
      width: 70vw;
    }

    @include min-w-lg() {
      width: 50vw;
    }
  }

  &.md {
    width: 55vw;

    @include max-w-xs() {
      width: 95vw;
    }

    @include min-w-sm() {
      width: 90vw;
    }

    @include min-w-md() {
      width: 70vw;
    }

    @include min-w-lg() {
      width: 60vw;
    }
  }

  &.lg {
    width: 70vw;

    @include max-w-xs() {
      width: 95vw;
    }

    @include min-w-sm() {
      width: 95vw;
    }

    @include min-w-md() {
      width: 80vw;
    }

    @include min-w-lg() {
      width: 70vw;
    }
  }

  &.full {
    width: 99vw;
    height: 98vh;
  }
}

.modal-content {
  max-height: 70vh;
  padding: 2em;
  overflow-y: auto;
}
