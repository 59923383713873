.nav-list {
  height: 100%;
  padding-top: 2em;
}

.nav-item {
  display: block;
  border: none;
}

.nav-item-button {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.65em 0.9em;
  transition: background-color 0.3s;
}

.nav-icon-wrapper {
  justify-content: flex-start;
}

.nav-icon {
  font-size: 1.35rem;
  color: var(--primary-text-color);
}

.nav-link {
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--primary-text-color);
  font-weight: 400;
  padding-inline-start: 6px;

  &:hover {
    background-color: var(--primary-color);
    color: white;

    .nav-icon {
      color: white;
    }
  }
}

.nav-link-active {
  padding-inline-start: 0px;
  background-color: var(--gray-100);
  border-inline-start-style: solid;
  border-inline-start-color: var(--primary-color);
  border-inline-start-width: 5px;
}

.profile-wrapper {
  padding-top: 0.5em;
  padding-inline-start: 0px;
  margin-inline-start: 6px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.name {
  color: var(--primary-text-color);
  text-transform: capitalize;
  padding-inline-start: 6px;
  font-weight: 400;
  text-decoration: none;
}

.profile-link {
  display: block;
  text-decoration: none;
}
