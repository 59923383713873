.title {
  position: relative;
  padding: 0 0 0.5em 0;
  text-transform: capitalize;
}

.underline {
  display: block;
  height: 2px;
  background-color: var(--primary-color);
  border-radius: 15px;
}

.main-title {
  text-align: center;
  margin-bottom: 1em;
  .underline {
    width: 15%;
    margin: 0 auto;
    min-width: 8em;
  }
}

.secondary-title {
  text-align: start;
  margin-bottom: 0.5em;
  padding-inline-start: 5px;
  &::before {
    content: '';
    top: 0;
    bottom: 11px;
    margin-inline-start: -7px;
    background-color: var(--primary-color);
    position: absolute;
    width: 3px;
  }

  .underline {
    display: none;
  }
}
