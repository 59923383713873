.data-grid-wrapper {
  margin-top: 20px;
  border-radius: 12px;
  box-shadow:
    rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  height: 70vh;
}

.grid-header {
  background-color: var(--primary-color);
  color: white;
  text-transform: capitalize;
}

.MuiDataGrid-columnHeaders {
  background-color: var(--primary-color);
}

.MuiDataGrid-columnSeparator,
.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.MuiDataGrid-root {
  border-radius: 12px;
}

.MuiDataGrid-columnHeaders {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.css-v3t5q3-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -5px;
}

.grid-delete-actions {
  background-color: #dc354610;
}

.grid-activate-actions {
  background-color: #4edc3510;
}

.grid-edit-actions {
  background-color: #006da410;
}
