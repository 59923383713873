@import 'src/lib/styles/mixin.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --primary-color: #006da4;
  --error: #dc3545;
  --light-700: rgb(255 255 255);
  --light-600: rgb(248 250 252);
  --light-500: rgb(241 245 249);
  --light-400: rgb(226 232 240);
  --light-300: rgb(203 213 225);
  --light-200: rgb(148 163 184);
  --light-100: rgb(100 116 139);

  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;

  --bg-color: var(--light-600);
  --border-color: var(--gray-300);
  --muted: var(--gray-400);
  --primary-text-color: #000;
  --secondary-text-color: var(--gray-500);

  @include max-w-xs() {
    font-size: calc(1vw + 12px);
  }

  @include min-w-md() {
    font-size: 16px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// scrollbar style
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
//scroll color
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 0px;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  opacity: 0.9;
}
//scroll color when it active
::-webkit-scrollbar-thumb:active {
  background: var(--primary-color);
  opacity: 0.8;
}
//scrollbar (entire scrollbar)
::-webkit-scrollbar-track {
  background: var(--light-500);
  border: 0px none #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
