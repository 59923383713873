.icon-button {
  width: 2em;
  height: 2em;
  background-color: var(--light-600);
}

.icon {
  width: 1.5em;
  height: 0.7em;
}
