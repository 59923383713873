.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-wrapper {
  width: 20em;
  margin-inline-end: 15px;
}

.loader {
  height: 5px;
  width: 11em;
  --c: no-repeat linear-gradient(#00cecb 0 0);
  background: var(--c), var(--c), #16f1ed;
  background-size: 60% 100%;
  animation: l16 3s infinite;
  border-radius: 15px;
  overflow: hidden;
}
@keyframes l16 {
  0% {
    background-position:
      -150% 0,
      -150% 0;
  }
  66% {
    background-position:
      250% 0,
      -150% 0;
  }
  100% {
    background-position:
      250% 0,
      250% 0;
  }
}
